<template lang="pug">
  main#main.organization-address
    b-container(fluid)
      wc-breadcrumb(icon='fal fa-map-marker')
      section.l-section
        .l-section-h
          b-row.mb-5
            b-col
              router-view
</template>

<script>
import WcBreadcrumb from '@components/shared/WcBreadcrumb'

export default {
  name: 'organization-address',
  components: {
    WcBreadcrumb,
  },
}
</script>
